<template>
  <div class="flex items-center mt-2">
    <div class="flex items-center w-56 select-none text-sm leading-tight">
      {{ filter.label }}
    </div>
    <component
      :is="component"
      :model-value="modelValue"
      class="w-full"
      :resource="filter.resource"
      :do-initial-fetch="filter.doInitialFetch"
      :computed-fields="filter.computedFields || ['lastname', 'firstname']"
      :modify-query="filter.modifyQuery"
      :compute-name="filter.computeName"
      :fetch-handler="filter.fetchHandler"
      :override-initial-fetch-handler="filter.overrideInitialFetchHandler"
      :options-value="filter.optionsValue"
      :result-filter="filter.resultFilter"
      :no-repeat-key="filter.noRepeatKey"
      :placeholder="filter.placeholder"
      :append-to-body="appendToBody"
      :popper-append-to-body="appendToBody"
      :disabled="filter.disabled || false"
      @resource-set="handleFilterInput"
      @initial-resource-set="handleFilterInput"
    />
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'RemoteSearchSelectWrapper',
  components: {
    RemoteSearchSelectLegacy: defineAsyncComponent(() =>
      import('@/components/remote-search-select')
    ),
    RemoteSearchSelect: defineAsyncComponent(() =>
      import('@components/select/remote-search')
    )
  },
  props: {
    filter: {
      type: Object,
      required: true
    },
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    useOld: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'resource-set'],
  computed: {
    component() {
      return this.useOld ? 'RemoteSearchSelectLegacy' : 'RemoteSearchSelect'
    }
  },
  methods: {
    handleFilterInput(v) {
      const { expandOriginalData } = this.filter
      if (v) {
        let queryInput
        if (typeof expandOriginalData === 'function') {
          // NOTE: We expand the original data with the original user input for cases in which we would need to use this input later on, e.g. to find the staff's phone number the user meant in an object of several phone numbers (main, mobile, office, etc.).
          queryInput = expandOriginalData(v, v.queryInput)
        }
        v.queryInput = queryInput
      }

      this.$emit('resource-set', v || null)

      const newValue = v?.[this.filter.optionsValue || 'id']?.toString() || null
      this.$emit('update:modelValue', newValue)
    }
  }
}
</script>
